<template lang="pug">
include ../../../../configs/template.pug
b-card(
  v-if="Object.keys(statementInfo).length"
  header-tag="header").pb-4.mt-0
  template(#header)
    div.flex-row-sb
      div.text-uppercase.text-left {{ $t(queryStatementType ? 'statementDetach' : 'statementAttach') }}
        span(v-if="checkAccess('backOffice')").pl-2 (ID: {{ statementInfo.id }})
      div.documentIconControl
        unicon(
          @click="back('crewing-statement')"
          name="multiply"
          fill="#42627e"
          height="25px"
          width="25px").close
  div.seafarerInfoList
    div.w-50
      b {{ $t('number') }}:
      span {{ statementInfo.id }}
    div.w-50
      b {{ $t('createDate') }}:
      span {{ statementInfo.created_at }}
    div(v-if="statementInfo.sailor").w-50
        b {{ $t('fullName') }}:
        span {{ statementInfo.sailor.full_name_ukr }}
    div(v-if="statementInfo.sailor_key").w-50
      b {{ $t('fullName') }}:
      span {{ `${statementInfo.sailor_key.last_name_ukr} ${statementInfo.sailor_key.first_name_ukr} ${statementInfo.sailor_key.middle_name_ukr}` }}
    div(v-if="queryStatementType").w-50
      b {{ $t('crewingManager') }}:
      span {{ crewingManagerName(statementInfo.manager) }}
    div(v-else).w-50
      b {{ $t('crewing') }}:
      span {{ companyName(statementInfo.crewing) }}
    div.w-100
      b {{ $t('status') }}:
      span(:class="getStatus(statementInfo.status_document)") {{ statusById(statementInfo.status_document)[labelName] }}
    div(v-if="existComments.length").w-100
      b {{ $t('comment') }}:
      div(
        v-for="item in existComments"
        key="item.comment")
        span {{ item.comment }}
  div(v-if="STATUSES_STATEMENT_CONSTANTS.CREWING_CONECT_STATEMENT.REJECT !== statementInfo.status_document")
    div.col-12.pb-0
      +select('status', 'statusesList', 'status', '"name_ukr"')(outlined)
    div(v-if="status === 68").col-12.pb-0
      +textarea-validation('comment', 'comment', '["required", "alphaENText"]')(outlined)
    v-btn(
      :disabled="!status"
      :loading="isLoader"
      @click="setStatus"
      color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { detachStatementComment } from '@/mixins/validationRules'

export default {
  name: 'StatementISCDocument',
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      getStatus,
      SUCCESS_CODE,
      statementInfo: {},
      queryStatementType: this.$route.query.statement_type,
      comment: null,
      checkAccess,
      status: null,
      isLoader: false,
      existComments: []
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      crewings: state => state.crewing.crewingCompanies,
      crewingManagers: state => state.crewing.crewingManagers,
      statuses: state => state.directory.statuses
    }),
    ...mapGetters([
      'statusById',
      'crewingManagerByID',
      'crewingCompanyByID'
    ]),
    statusesList () {
      const statuses = [68, 67]
      !this.queryStatementType && statuses.splice(1, 1)
      return this.statuses.filter(item => statuses.includes(item.id))
    }
  },
  validations () { return detachStatementComment() },
  mounted () {
    !this.crewingManagers.length && this.getCrewingCompanyManagers()
    const methodName = this.queryStatementType
      ? 'getDetachCrewingStatement' : 'getCrewingStatement'
    this[methodName](this.$route.params.id).then(response => {
      SUCCESS_CODE.includes(response.code) && (this.statementInfo = response.data)
      this.statementInfo.status_document === 68 && this.getComments()
    })
  },
  methods: {
    ...mapActions([
      'getCrewingStatement',
      'getDetachCrewingStatement',
      'getCommentCrewingStatement',
      'getCommentDetachCrewingStatement',
      'updateCrewingStatement',
      'updateDetachCrewingStatement',
      'setCommentCrewingStatement',
      'setDetachCommentCrewingStatement',
      'getCrewingCompanyManagers'
    ]),
    getComments () {
      const methodName = this.queryStatementType
        ? 'getCommentDetachCrewingStatement' : 'getCommentCrewingStatement'
      this[methodName](this.$route.params.id)
        .then(response => { this.existComments = response.data })
    },
    back () {
      const query = this.queryStatementType ? '?statement_type=detach' : ''
      this.$router.push({ path: `/crewing-statement${query}` })
    },
    setStatus () {
      if (this.status === 68) {
        this.$v.$touch()
        if (this.$v.$invalid) return false
      }
      this.isLoader = true
      const body = {
        id: this.$route.params.id,
        body: {
          status_document: this.status
        }
      }
      const methodName = this.queryStatementType
        ? 'updateDetachCrewingStatement' : 'updateCrewingStatement'
      this[methodName](body).then(response => {
        if (SUCCESS_CODE.includes(response.code)) {
          this.status === 68 ? this.setComment(body) : this.onSuccess()
        } else this.isLoader = false
      })
    },
    onSuccess () {
      this.isLoader = false
      this.$notification.success('success')
      this.back()
    },
    setComment (body) {
      body.body = {
        comment: this.comment,
        additional_info: {}
      }
      const methodName = this.queryStatementType
        ? 'setDetachCommentCrewingStatement' : 'setCommentCrewingStatement'
      this[methodName](body)
        .then(response => {
          SUCCESS_CODE.includes(response.code) && this.onSuccess()
        }).finally(() => { this.isLoader = false })
    },
    crewingManagerName (id) {
      return this.crewingManagerByID(id)?.crewingHeadFullName || id
    },
    companyName (id) {
      return this.crewingCompanyByID(id)?.name_ukr || id
    }
  }
}
</script>
